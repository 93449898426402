import styled from 'styled-components';
import FuzzleLogo from './FuzzleLogo';

export default function TopNav(): JSX.Element {
  return (
    <Container>
      <FuzzleLogo />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 16px 0 16px 32px;
`;
