const breakpoints = {
  lg: '1280px',
  md: '1024px',
  sm: '768px'
};
const theme = {
  darkNavy: '#1D1F3D',
  navy: '#1A296D',
  purple: '#8255CB',
  pink: '#FFA7F8',
  white: '#FFFFFF',
  lightBlue: '#77ACFB',
  ...breakpoints
};

export const device = {
  laptop: `@media only screen and (max-width: ${breakpoints.lg})`,
  tablet: `@media only screen and (max-width: ${breakpoints.md})`,
  mobile: `@media only screen and (max-width: ${breakpoints.sm})`
};

export default theme;
