import styled from 'styled-components';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMetaMask } from 'metamask-react';
import { ethers } from 'ethers';
import { useTimer } from 'react-timer-hook';
import axios from 'axios';
import useAxios from 'axios-hooks';

import LockLogo from 'assets/lock.svg';
import { AxiosWrapper } from './AxiosWrapper';
import Spinner from './Spinner';

declare let ethereum: {
  request<T>(params: { method: string }): Promise<T>;
  enable(): void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  on(event: string, callback: Function): void;
};

export default function Code(): JSX.Element {
  const { status, account } = useMetaMask();
  const history = useHistory();
  const [pinRequest, fetchPinRequest] = useAxios({ url: '/api/auth/signature', method: 'POST' }, { manual: true });
  const [isSigned, setSign] = useState(false);
  const [canceled, setCancel] = useState(false);

  const time = useMemo(() => {
    const t = new Date();
    t.setSeconds(t.getSeconds() + 600);
    return t;
  }, []);

  const timer = useTimer({ expiryTimestamp: time, autoStart: false });

  useEffect(() => {
    if (status === 'notConnected') {
      history.push('/connect-wallet');
    }
  });

  useEffect(() => {
    async function getPin(): Promise<void> {
      if (!account) return;

      try {
        setSign(false);
        setCancel(false);
        const { data } = await axios.post('/api/session', { address: account });
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner(account);
        const sig = await signer.signMessage(data.id as string);

        await fetchPinRequest({
          data: { sig: sig }
        });
        setSign(true);
        timer.start();
      } catch (e) {
        setCancel(true);
        console.error(e);
      }
    }

    void getPin();
  }, [account, fetchPinRequest]);

  function onClick(): void {
    setSign(false);
    setCancel(false);
    window.location.reload();
  }

  function cardInfo(): JSX.Element {
    if (!isSigned) {
      return (
        <div className='signing'>
          <h4>Sign your account to get a 6 digit code</h4>
          <p></p>
          <p></p>
          { canceled ? <button className='new-pin' onClick={onClick}>Get New Code</button> : <Spinner className='spinner' /> }
        </div>
      );
    }

    if (timer.isRunning) {
      return (
        <div className='running'>
          <h4>Enter the code below into your fuzzle app to connect</h4>
          <p>For security purposes, this code will expire in</p>
          <p>{timer.minutes.toString().padStart(2, '0')} : {timer.seconds.toString().padStart(2, '0')} minutes</p>
          <h1>{pinRequest.data}</h1>
        </div>
      );
    }

    return (
      <div className='expired'>
        <h4>Your code has expired, click on the button to get a new code</h4>
        <p>Get a new code to connect your wallet</p>
        <p></p>
        <button className='new-pin' onClick={onClick}>Get New Code</button>
      </div>
    );
  }

  return (
    <Container>
      <div className="card">
        <img src={LockLogo} alt="Lock Logo" />
        <AxiosWrapper requests={[pinRequest]} >
          {cardInfo()}
        </AxiosWrapper>
      </div>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .card {
    margin-top: 220px;
    width: 447px;
    height: 481px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFF;
    border-radius: 15px;
    padding: 80px 50px;

    img {
      margin-bottom: 25px;
      text-align: center;
    }

    .signing,
    .running,
    .expired {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      h4,
      p {
        margin-bottom: 25px;
        text-align: center;
      }

      h4 {
        color: #000;
      }

      p {
        font-weight: 500;
        color: #8F8F8F;
        width: 310px;
      }

      h1 {
        color: #5F5F5F;
      }

      .new-pin {
        font-weight: 700;
        width: 100%;
        height: 40px;
      }
    }
  }
`;
