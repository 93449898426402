import {
  BrowserRouter as Router
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import theme from './styles/theme';
import GlobalStyle from 'styles/global';
import RootContainer from './components/RootContainer';

function App(): JSX.Element {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <RootContainer />
      </ThemeProvider>
    </Router>
  );
}

export default App;
