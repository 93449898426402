import './index.css';
import { render } from 'react-dom';
import React, { Suspense } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { MetaMaskProvider } from 'metamask-react';

import App from './App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: 'https://8758283cd05a498fb519b9c0e9c49bec@o1034435.ingest.sentry.io/6523515',
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENV,
  release: process.env.REACT_APP_VERSION,
  tracesSampleRate: 1.0
});

Sentry.configureScope(scope => {
  scope.setTag('host', window.location.hostname || window.location.host);
});

render(
  <React.StrictMode>
    <MetaMaskProvider>
      <Suspense fallback={<></>}>
        <App />
      </Suspense>
    </MetaMaskProvider>
  </React.StrictMode>,
  document.querySelector('#root')
);

reportWebVitals();
