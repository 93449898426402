import { ReactNode } from 'react';
import { ResponseValues } from 'axios-hooks';
import { useErrorHandler } from 'react-error-boundary';

interface IAxiosWrapper {
  requests: ResponseValues<unknown, unknown, Error>[];
  children?: ReactNode;
}

export function AxiosWrapper({ requests, children }: IAxiosWrapper): JSX.Element {
  const error = requests.find(c => c.error)?.error;
  const loading = requests.some(c => c.loading);
  const handleError = useErrorHandler();

  if (error) handleError(error);
  if (loading) return <></>;

  return (
    <>
      {children}
    </>
  );
}
