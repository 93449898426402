import styled from 'styled-components';
import TopNav from './nav/TopNav';

interface IPageTemplate {
  children: JSX.Element | JSX.Element[];
}

export default function PageTemplate({ children }: IPageTemplate): JSX.Element {
  return (
    <Container>
      <TopNav />
      <div id="children">
        {children}
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  #children {
    max-width: 1200px;
    align-self: center;
  }
`;
