import styled, { css } from 'styled-components';
import theme, { device } from 'styles/theme';

const button = css`
  border: none;
  background-color: ${theme.purple};
  border-radius: 0.25rem;
  color: white;
  font-size: 17px;
  text-transform: uppercase;
  transition: opacity 250ms;

  &:hover {
    cursor: pointer;
    background-color: ${theme.purple};
  }

  &:disabled {
    opacity: 0.8;

    &:hover {
      cursor: wait;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.Toastify__close-button {
    background-color: transparent;
  }
`;

export default button;
