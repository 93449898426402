import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useMetaMask } from 'metamask-react';

import WavingFuzzle from 'assets/waving-fuzzle.svg';

export default function ConnectWallet(): JSX.Element {
  const { connect } = useMetaMask();
  const history = useHistory();

  async function onClick(): Promise<void> {
    await connect();
    history.push('/code');
  }

  return (
    <Container>
      <button className='button' onClick={onClick}><h4>Connect Wallet</h4></button>
      <img className='waving-fuzzle' src={WavingFuzzle} alt='Waving Fuzzle' />
    </Container>
  );
}

const Container = styled.div`
  height: 780px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 103px;
  margin-top: 88px;

  .button {
    width: 399px;
    height: 90px;
  }
`;
