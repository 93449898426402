import { StyledLink } from 'styles/styled-components';
import ErrorPage from './ErrorPage';

export function PageNotFound(): JSX.Element {
  return (
    <ErrorPage>
      <p>
          This page does not exist or was removed.
      </p>
      <p>
          We suggest you go back to <StyledLink to="/home">home.</StyledLink>
      </p>
    </ErrorPage>
  );
}
