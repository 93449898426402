import styled from 'styled-components';

export default function FuzzleLogo(): JSX.Element {
  return (
    <Container href='https://collectfuzzle.com/'>
      <div id="fuzzle">
        <svg
          _ngcontent-serverapp-c86=""
          id="fuzzle-logo"
          data-name="fuzzle-logo"
          width="197"
          height="48"
          viewBox="0 0 197 48"
          fill="none"
          aria-label="Fuzzle logo"
          role="img" xmlns="http://www.w3.org/2000/svg"
          className="max-w-full w-32 hidden md:block lg:w-auto h-auto"
        >
          <path
            _ngcontent-serverapp-c86=""
            d="
              M75.1529 13.9753 H89.5382 C89.7233 13.9753 89.9009 14.0491 90.0321 14.1802
              C90.1632 14.3114 90.2372 14.4894 90.2379 14.6753 V21.177
              C90.2372 21.3629 90.1632 21.5409 90.0321 21.6721
              C89.9009 21.8032 89.7233 21.877 89.5382 21.877 H84.2933V26.303 H89.5382
              C89.7238 26.303 89.9017 26.3771 90.033 26.5088C90.1642 26.6405 90.2379 26.8192 90.2379 27.0055
              V33.9615 C90.2372 34.1474 90.1632 34.3254 90.0321 34.4566C89.9009 34.5877 89.7233 34.6613 89.5382 34.6613
              H84.2933 V41.5012 C84.2933 41.687 84.2199 41.8653 84.0893 41.997 C83.9587 42.1287 83.7813 42.203 83.5962 42.2037
              H75.1529 C74.9673 42.2037 74.7893 42.1296 74.6581 41.9979 C74.5269 41.8662 74.4531 41.6875 74.4531 41.5012
              V14.6831 C74.4525 14.5904 74.4701 14.4986 74.5049 14.4127 C74.5398 14.3269 74.5912 14.2487 74.6563 14.1829
              C74.7213 14.1172 74.7986 14.065 74.8839 14.0294C74.9691 13.9937 75.0605 13.9753 75.1529 13.9753Z"
            fill="currentColor"
            data-darkreader-inline-fill=""
          ></path>
          <path
            _ngcontent-serverapp-c86=""
            d="
              M92.7617 32.4824 V14.683C92.7617 14.4974 92.8351 14.3193 92.9659 14.1881
              C93.0966 14.0568 93.2739 13.9832 93.4587 13.9832 H101.942 C102.127 13.9832 102.304 14.0568 102.435 14.1879
              C102.567 14.3191 102.641 14.4971 102.641 14.683 V32.4904 C102.641 33.8109 103.244 34.7087 104.367 34.7087
              C105.49 34.7087 106.095 33.8214 106.095 32.4904 V14.683 C106.095 14.4974 106.168 14.3193 106.299 14.1881
              C106.43 14.0568 106.607 13.9832 106.792 13.9832 H115.275 C115.46 13.9832 115.638 14.0568 115.769 14.1879
              C115.9 14.3191 115.974 14.4971 115.975 14.683 V32.4904 C115.975 39.1824 111.919 43.0142 104.367 43.0142
              C96.8546 43.0063 92.7617 39.1744 92.7617 32.4824Z"
            fill="currentColor"
            data-darkreader-inline-fill=""
          ></path>
          <path
            _ngcontent-serverapp-c86=""
            d="
              M125.865 21.8768 H119.176 C118.991 21.8768 118.814 21.803 118.683 21.6719 C118.551 21.5407 118.477 21.3627 118.477 21.1768
              V14.683 C118.477 14.4971 118.551 14.3191 118.683 14.1879 C118.814 14.0568 118.991 13.9832 119.176 13.9832 H137.62
              C137.736 13.9829 137.85 14.0114 137.952 14.0663 C138.054 14.1212 138.14 14.2007 138.204 14.2977
              C138.268 14.3946 138.306 14.5059 138.317 14.6216 C138.327 14.7372 138.308 14.8536 138.262 14.9602 L130.042 34.3046 H137.297
              C137.482 34.3046 137.66 34.3787 137.792 34.5104 C137.923 34.6422 137.997 34.8208 137.997 35.0071 V41.5063
              C137.997 41.6926 137.923 41.8713 137.792 42.003 C137.66 42.1347 137.482 42.2088 137.297 42.2088 H118.29
              C118.174 42.2089 118.061 42.1802 117.959 42.1253 C117.857 42.0703 117.77 41.991 117.706 41.8942
              C117.642 41.7974 117.603 41.6863 117.593 41.5706C117.582 41.455 117.6 41.3385 117.646 41.2316L125.865 21.8768Z"
            fill="currentColor"
            data-darkreader-inline-fill=""
          ></path>
          <path
            _ngcontent-serverapp-c86=""
            d="
              M148.001 21.8768 H141.31 C141.125 21.8768 140.947 21.803 140.816 21.6719 C140.685 21.5407 140.611 21.3627 140.61 21.1768
              V14.683 C140.611 14.4971 140.685 14.3191 140.816 14.1879 C140.947 14.0568 141.125 13.9832 141.31 13.9832 H159.754
              C159.869 13.9831 159.983 14.0118 160.085 14.0667 C160.187 14.1216 160.274 14.201 160.338 14.2978 C160.402 14.3946 160.44 14.5057 160.451 14.6213
              C160.462 14.7369 160.443 14.8533 160.398 14.9602 L152.178 34.3046 H159.43 C159.616 34.3046 159.794 34.3787 159.925 34.5104
              C160.056 34.6422 160.13 34.8208 160.13 35.0071 V41.5063 C160.13 41.6926 160.056 41.8713 159.925 42.003 C159.794 42.1347 159.616 42.2088 159.43 42.2088
              H140.426 C140.311 42.2084 140.197 42.1794 140.096 42.1243 C139.994 42.0693 139.908 41.9897 139.844 41.893 C139.781 41.7963 139.742 41.6854 139.731 41.57
              C139.721 41.4546 139.739 41.3383 139.784 41.2316L148.001 21.8768Z"
            fill="currentColor"
            data-darkreader-inline-fill=""
          ></path>
          <path
            _ngcontent-serverapp-c86=""
            d="
              M163.672 13.9753 H172.115 C172.3 13.9753 172.478 14.0491 172.609 14.1802 C172.74 14.3114 172.814 14.4894 172.815 14.6753 V33.8136 H178.099
              C178.284 33.8136 178.462 33.8872 178.593 34.0184 C178.724 34.1496 178.798 34.3276 178.799 34.5134 V41.4986 C178.799 41.6849 178.725 41.8636 178.594 41.9953
              C178.463 42.1271 178.285 42.2011 178.099 42.2011 H163.672 C163.487 42.2004 163.309 42.1261 163.179 41.9944 C163.048 41.8628 162.975 41.6845 162.975 41.4986
              V14.6831 C162.974 14.5906 162.991 14.4987 163.025 14.4129 C163.06 14.327 163.111 14.249 163.176 14.1832 C163.241 14.1174 163.318 14.065 163.403 14.0294
              C163.488 13.9937 163.58 13.9753 163.672 13.9753V13.9753Z"
            fill="currentColor"
            data-darkreader-inline-fill=""
          ></path>
          <path
            _ngcontent-serverapp-c86=""
            d="
              M182.026 13.9753 H197.092 C197.278 13.9753 197.455 14.0491 197.586 14.1802 C197.717 14.3114 197.791 14.4894 197.792 14.6753 V20.4508
              C197.791 20.6366 197.717 20.8146 197.586 20.9458 C197.455 21.077 197.278 21.1507 197.092 21.1507 H191.166 V24.7819 H197.092 C197.278 24.7819 197.455 24.8555 197.586 24.9867
              C197.717 25.1179 197.791 25.2959 197.792 25.4817 V30.8532 C197.792 31.0395 197.718 31.2182 197.587 31.3499 C197.456 31.4817 197.278 31.5557 197.092 31.5557 H191.166
              V35.0179 H197.092 C197.278 35.0179 197.456 35.092 197.587 35.2237 C197.718 35.3554 197.792 35.5341 197.792 35.7204 V41.4933 C197.792 41.6796 197.718 41.8583 197.587 41.99
              C197.456 42.1218 197.278 42.1958 197.092 42.1958 H182.026 C181.84 42.1958 181.662 42.1218 181.531 41.99 C181.4 41.8583 181.326 41.6796 181.326 41.4933 V14.6831
              C181.325 14.5904 181.343 14.4986 181.378 14.4127 C181.413 14.3269 181.464 14.2487 181.529 14.1829 C181.594 14.1172 181.672 14.065 181.757 14.0294C181.842 13.9937 181.934 13.9753 182.026 13.9753Z"
            fill="currentColor"
            data-darkreader-inline-fill=""
          ></path>
          <path
            _ngcontent-serverapp-c86=""
            d="
              M57.9225 24.9558 L56.1304 26.5754 L58.5359 26.3544 L58.4622 25.5529 C58.5359 26.3544 58.5359 26.3544 58.536 26.3544 L58.5363 26.3544 L58.5369 26.3544 L58.5388 26.3542 L58.5452 26.3536
              L58.5683 26.3513 C58.5882 26.3494 58.6169 26.3465 58.6536 26.3427 C58.7269 26.3351 58.8322 26.3236 58.9629 26.3081 C59.2239 26.277 59.588 26.2294 60.0015 26.1631
              C60.3426 26.1084 60.727 26.0395 61.1203 25.9544 C58.8279 31.6968 54.9396 36.6703 49.9048 40.2728 C44.4462 44.1786 37.9111 46.2788 31.2088 46.2817 C24.5095 46.2804 17.9767 44.1835 12.5185 40.2822
              C7.48195 36.6823 3.59123 31.7108 1.29592 25.9695 C1.69322 26.0544 2.08157 26.1232 2.42611 26.1778 C2.83958 26.2434 3.2033 26.2906 3.46405 26.3214 C3.59459 26.3369 3.69974 26.3483 3.7729 26.3559
              C3.80949 26.3597 3.83812 26.3626 3.85794 26.3645 L3.881 26.3667 L3.88739 26.3673 L3.88927 26.3675 L3.88988 26.3676 L3.8901 26.3676 C3.89019 26.3676 3.89027 26.3676 3.96398 25.5661 L3.89027 26.3676
              L6.29455 26.5887 L4.5039 24.9691 C1.96152 22.6696 1.09917 19.4303 0.96069 16.5726 C1.97261 17.3705 3.41638 18.2278 5.18641 18.5588 L6.36543 18.7793 L6.12261 17.6047
              C6.10653 17.5269 6.09306 17.466 6.08086 17.4108 C6.06472 17.3378 6.05081 17.275 6.03604 17.1964 L6.03593 17.1958 C4.69391 10.0836 8.3367 4.43556 11.2085 2.23358 L11.2087 2.23344
              C12.1749 1.49214 12.8555 1.11491 13.3183 0.941437 C13.7306 0.786862 13.9049 0.817276 13.9411 0.826937 C13.9429 0.830348 13.9451 0.835016 13.9475 0.841151 C13.9506 0.849017 13.9533 0.857161 13.9555 0.865076
              C13.2499 6.75809 14.5906 10.2271 15.5044 11.824L16.1833 13.0104 L16.8915 11.8412 C18.9661 8.41582 22.7592 4.2469 29.3043 3.22029 C28.8006 4.14175 28.4709 5.15463 28.3379 6.20799 L28.1456 7.7309
              L29.5077 7.02303 C32.8153 5.304 37.0207 4.20736 42.0849 5.04016 C41.0822 6.03172 40.2959 7.23047 39.785 8.56081 L39.4877 9.33493 L40.2703 9.60903 C40.4962 9.68816 40.7081 9.76326 40.9174 9.84484
              L40.9174 9.84494 L40.9298 9.84956 L41.0508 9.89443 L41.0604 9.89799L41.0701 9.9013C41.2772 9.97219 41.4856 10.0561 41.7175 10.1498 C41.7178 10.1499 41.7181 10.15 41.7185 10.1502 L41.9445 10.2421
              L42.58 10.5133 L42.7301 10.5811 L42.7363 10.5838 C42.9074 10.6594 43.0752 10.7358 43.2476 10.8143 C43.333 10.8532 43.4196 10.8926 43.5082 10.9327 L43.6867 11.0198 L43.6921 11.0224 L44.2764 11.3132
              L44.5034 11.4298 L44.5035 11.4299 C44.7349 11.5487 44.9545 11.6628 45.1633 11.7822 L45.227 11.8186 L45.2576 11.8294 C45.4348 11.9259 45.6141 12.0284 45.7958 12.1337 L46.4933 12.5381 L46.8965 11.8399
              C47.8118 10.255 49.1685 6.76889 48.4627 0.851263 C48.4649 0.843229 48.4676 0.834953 48.4707 0.826971 C48.473 0.821229 48.4751 0.816786 48.4767 0.813468 C48.5142 0.803716 48.6885 0.774753 49.0977 0.928219
              C49.5602 1.1017 50.2407 1.47895 51.207 2.22028 L51.2082 2.22126 C53.6596 4.09419 56.6785 8.47742 56.6788 14.141 C56.6779 15.1613 56.5814 16.1791 56.3905 17.1812 L56.3902 17.1831
              C56.3754 17.2616 56.3616 17.3241 56.3455 17.3967 C56.3333 17.4519 56.3197 17.513 56.3035 17.5914 L56.0607 18.7658 L57.2396 18.5455 C59.013 18.2142 60.4575 17.3555 61.4697 16.5576
              C61.3299 19.416 60.4673 22.6559 57.9225 24.9558Z"
            stroke="currentColor"
            strokeWidth="1.60981"
            data-darkreader-inline-stroke=""
          ></path>
          <path
            _ngcontent-serverapp-c86=""
            d="
              M41.5598 22.0012 C40.3844 22.0007 39.2352 22.3502 38.2577 23.0055 C37.2801 23.6608 36.5181 24.5925 36.068 25.6827 C35.618 26.7729 35.5001 27.9727 35.7293 29.1302 C35.9585 30.2877 36.5244 31.3509 37.3556 32.1854
              C38.1867 33.0198 39.2457 33.5881 40.3986 33.8182 C41.5515 34.0483 42.7465 33.9299 43.8323 33.4781 C44.9182 33.0262 45.8461 32.2612 46.4988 31.2797 C47.1515 30.2983 47.4996 29.1445 47.4991 27.9643
              C47.4991 26.3828 46.8734 24.8661 45.7595 23.7478 C44.6457 22.6295 43.1351 22.0012 41.5598 22.0012Z M41.5598 31.3262 C40.8976 31.3262 40.2502 31.129 39.6995 30.7596 C39.1489 30.3902 38.7197 29.8652 38.4663 29.2509
              C38.2129 28.6366 38.1465 27.9606 38.2757 27.3085 C38.4049 26.6564 38.7239 26.0573 39.1921 25.5872 C39.6604 25.117 40.257 24.7968 40.9066 24.6671 C41.5561 24.5374 42.2294 24.6039 42.8412 24.8583
              C43.4531 25.1128 43.976 25.5438 44.3439 26.0966C44.7119 26.6495 44.9083 27.2994 44.9083 27.9643C44.9069 28.855 44.5535 29.7087 43.9257 30.338C43.2979 30.9674 42.447 31.3209 41.5598 31.3209V31.3262Z"
            fill="currentColor"
            data-darkreader-inline-fill=""
          ></path>
          <path
            _ngcontent-serverapp-c86=""
            d="
              M20.8775 22.0012 C19.7022 22.0012 18.5533 22.3511 17.5761 23.0068 C16.5989 23.6624 15.8373 24.5944 15.3876 25.6847 C14.938 26.7749 14.8204 27.9745 15.0499 29.1318 C15.2794 30.2892 15.8456 31.352 16.6769 32.1863
              C17.5081 33.0205 18.5671 33.5885 19.7199 33.8184 C20.8727 34.0483 22.0676 33.9298 23.1533 33.4778 C24.239 33.0259 25.1668 32.2609 25.8194 31.2795 C26.472 30.2981 26.82 29.1444 26.8195 27.9643
              C26.8198 27.1809 26.6664 26.4051 26.3679 25.6813 C26.0693 24.9575 25.6316 24.2999 25.0797 23.746 C24.5279 23.1922 23.8727 22.7531 23.1516 22.4537 C22.4306 22.1543 21.6578 22.0005 20.8775 22.0012Z
              M20.8775 31.3262 C20.2153 31.3262 19.5679 31.129 19.0173 30.7596 C18.4666 30.3902 18.0374 29.8652 17.784 29.2509 C17.5305 28.6366 17.4642 27.9606 17.5934 27.3085 C17.7226 26.6564 18.0415 26.0573 18.5098 25.5872
              C18.9781 25.117 19.5748 24.7968 20.2243 24.6671 C20.8738 24.5374 21.5471 24.6039 22.1589 24.8583 C22.7708 25.1128 23.2937 25.5438 23.6617 26.0966 C24.0296 26.6495 24.2259 27.2994 24.2259 27.9643
              C24.2246 28.855 23.8712 29.7087 23.2434 30.338C22.6156 30.9674 21.7647 31.3209 20.8775 31.3209V31.3262Z"
            fill="currentColor"
            data-darkreader-inline-fill=""
          ></path>
        </svg>
      </div>
    </Container>
  );
}

const Container = styled.a`
  align-self: flex-start;
`;
