import styled from 'styled-components';

import Brave from 'assets/brave.svg';
import Chrome from 'assets/chrome.svg';
import Edge from 'assets/edge.svg';
import Firefox from 'assets/firefox.svg';
import theme from 'styles/theme';

export default function BrowserNotSupported(): JSX.Element {
  return (
    <Container>
      <h2>Your Browser Is Not Supported</h2>
      <p>You are using a web browser we don&apos;t support yet. Please use one of the browsers below to access Metamask.</p>
      <div className="browsers">
        <a className='browser' href='https://www.google.com/intl/en_ca/chrome/' target='_blank' rel="noreferrer">
          <img src={Chrome} alt="Chrome Logo" />
          <p>Chrome</p>
        </a>
        <a className='browser' href='https://www.mozilla.org/en-CA/firefox/new/' target='_blank' rel="noreferrer">
          <img src={Firefox} alt="Firefox Logo" />
          <p>Firefox</p>
        </a>
        <a className='browser' href='https://brave.com/download/' target='_blank' rel="noreferrer">
          <img src={Brave} alt="Brave Logo" />
          <p>Brave</p>
        </a>
        <a className='browser' href='https://www.microsoft.com/en-us/edge' target='_blank' rel="noreferrer">
          <img src={Edge} alt="Edge Logo" />
          <p>Edge</p>
        </a>
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 210px;

  h2 {
    font-weight: 700;
    margin-bottom: 25px;
  }

  p {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 37px;
  }

  .browsers {
    display: flex;

    .browser {
      margin: 0 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: ${theme.lightBlue};

      img {
        width: 150px;
        height: 150px;
        margin-bottom: 2px;
      }

      p {
        font-size: 16px;
        font-weight: 600;
        color: ${theme.lightBlue};
      }
    }
  }
`;
