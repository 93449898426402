import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';

const anchor = css`
  font-weight: 600;

  &:visited {
    color: ${theme.white};
  }

  &:hover {
    color: ${theme.pink};
  }
`;

const StyledLink = styled(Link)`
  ${anchor}
`;

export { StyledLink };

export default anchor;
