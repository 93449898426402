import { css } from 'styled-components';
import theme, { device } from 'styles/theme';

const h6 = css`
  font-weight: 700;
  font-size: 14px;
`;

const h5 = css`
  font-weight: 400;
  font-size: 16px;
`;

const h4 = css`
  font-weight: 700;
  font-size: 25px;
`;

const h3 = css`
  font-size: 30px;
  font-weight: 400;
`;

const h2 = css`
  font-size: 45px;
  font-weight: 500;
`;

const h1 = css`
  font-size: 65px;
  font-weight: 700;
`;

export { h1, h2, h3, h4, h5, h6 };
