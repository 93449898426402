import { createGlobalStyle } from 'styled-components';
import { a, button, h1, h2, h3, h4, h5, h6, p } from './styled-components';
import theme from './theme';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Libre Franklin', sans-serif;
    color: ${theme.white};
  }

  button {
    ${button}
  }

  a {
    ${a}
  }

  h1 {
    ${h1}
  }

  h2 {
    ${h2}
  }

  h3 {
    ${h3}
  }

  h4 {
    ${h4}
  }

  h5 {
    ${h5}
  }

  h6 {
    ${h6}
  }

  p {
    ${p}
  }
`;

export default GlobalStyle;
