import styled from 'styled-components';
import { useMetaMask } from 'metamask-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import MetamaskLogo from 'assets/metamask.png';

export default function InstallMetaMask(): JSX.Element {
  const { status } = useMetaMask();
  const history = useHistory();

  useEffect(() => {
    if (status !== 'unavailable') {
      history.push('/connect-wallet');
    }
  }, [status, history]);

  return (
    <Container>
      <div className='left'>
        <h2>Metamask Extension Needed</h2>
        <p>For access, please download the metamask extension for your browser settings or click on the link below.</p>
        <button onClick={() => window.open('https://metamask.io/download/')}>Download Extension</button>
      </div>
      <img src={MetamaskLogo} alt="Metamask Logo" />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 245px;
  gap: 182px;

  .left {
    width: 633px;

    h2 {
      font-weight: 700;
      white-space: nowrap;
      margin-bottom: 25px;
    }

    p {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 25px;
    }

    button {
      width: 370px;
      height: 63px;
      font-size: 16px;
      font-weight: 700;
    }
  }

  img {
    width: 266px;
    height: 266px;
  }
`;
