import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import { detect } from 'detect-browser';
import { useMetaMask } from 'metamask-react';

import PageTemplate from './page-template/PageTemplate';
import { PageNotFound } from './error/PageNotFound';
import BrowserNotSupported from './BrowserNotSupported';
import InstallMetaMask from './InstallMetaMask';
import ConnectWallet from './ConnectWallet';
import Code from './Code';

function RootContainer(): JSX.Element {
  const browser = detect();
  const { status } = useMetaMask();
  const browserSupported = ['chrome', 'firefox', 'edge-chromium'].includes(browser?.name.toLowerCase() || '');

  return (
    <>
      <PageTemplate>
        <Switch>
          <Route exact path='/'>
            { browserSupported ? <Redirect to='/connect-wallet' /> : <Redirect to='/not-supported-browser' /> }
          </Route>
          <Route exact path='/connect-wallet'>
            { status === 'unavailable' ? <Redirect to='extension' /> : <ConnectWallet />}
          </Route>
          <Route exact path='/code'>
            { status === 'unavailable' ? <Redirect to='/extension' /> : <Code /> }
          </Route>
          <Route exact path='/not-supported-browser'>
            <BrowserNotSupported />
          </Route>
          <Route exact path='/extension'>
            { status !== 'unavailable' ? <Redirect to='/connect-wallet' /> : <InstallMetaMask />}
          </Route>
          <Route>
            <PageNotFound />
          </Route>
        </Switch>
      </PageTemplate>
    </>
  );
}

export default RootContainer;
